.card {
  z-index: 1020;
}

.title {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  color: inherit;
}

.containers {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 200px;
  width: 100%;
}

.containers .steps {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.steps .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  color: #999;
  font-size: 22px;
  font-weight: 500;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #e0e0e0;
  transition: all 200ms ease;
  transition-delay: 0s;
}

.steps .circle.active {
  transition-delay: 100ms;
  border-color: #000;
  color: #000;
  background: #000;
}

.containers .steps .progress-bar {
  position: absolute;
  height: 1px;
  width: 100%;
  background: #e0e0e0;
  z-index: -1;
}

.containers .progress-bar .indicator {
  position: absolute;
  height: 100%;
  width: 0%;
  background: #212529;
  transition: all 300ms ease;
}
