.title {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  color: inherit;
}
.line {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #d4d4d4;
}
.line::before {
  content: "or";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #8b8b8b;
  padding: 0 15px;
}
